<template>
  <div class="container">
    <div class="list" v-for="item in list" :key="item.QuestionnaireId" @click="toDetails(item.QuestionnaireId)">
      <span class="code">{{item.QuestionnaireId}}</span><span class="name">{{item.RealName}}</span><span>{{item.HospitalTitle}}</span>
    </div>
    <van-empty v-if="showEmpty" description="暂无数据" />
  </div>
</template>

<script>
export default {
  name: 'ProductFeedbackList',
  data() {
    return {
      list: [],
      showEmpty: false
    }
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.$axios.get("/Api/Api/Web/Answer/GetUserAndAnsList").then((res) => {
        this.list = res.Data;
        if(this.list.length == 0) {
          this.showEmpty = true;
        }
      })
    },
    toDetails(id) {
      this.$router.push({
        path: 'productFeedbackDetails',
        query: {
          id: id,
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #F9F9F9;
  .list {
    font-size: 14px;
    color: #333333;
    padding: 8px;
    background-color: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #CCCCCC;
    margin-bottom: 15px;
    .code {
      color: #CA001B;
    }
    .name {
      color: #07c160;
    }
  }
}
</style>